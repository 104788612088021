<template>
	<v-dialog
		:value="dialog"
		max-width="500"
		persistent scrollable
	>
		<v-card>
			<v-card-actions class="grey lighten-4 justify-space-between py-4">
				<div class="px22">
					{{ formTitle }}
				</div>
				<v-btn icon @click="close()">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-card-actions>

			<v-card-text class="py-6"
				style="height: 70vh;"
			>
				<v-container fluid>
					<v-row class="ma-0 pa-0">
						<v-col
							cols="12"
						>
							<text-field
								v-model="editedItem.requested_by_name"
								label="Staff (*)"
								name="user"
								icon="mdi-account-outline"
								:errors="formErrors"
								:clearable="false"
								:disabled="true"
							/>
						</v-col>
						<v-col
							cols="12"
						>
							<select-field
								v-model="editedItem.leave_type"
								label="Leave type"
								name="leave_type"
								:items="$helper.LEAVE_TYPES"
								item-value="code"
								item-text="name"
								icon="mdi-calendar-week"
								:errors="formErrors"
							/>
						</v-col>
						<v-col
							cols="12"
						>
							<date-picker
								v-model="editedItem.from_date"
								label="From date (*)"
								name="from_date"
								icon="mdi-calendar-start"
								:errors="formErrors"
							/>
						</v-col>
						<v-col
							cols="12"
						>
							<date-picker
								v-model="editedItem.to_date"
								label="To date (*)"
								name="to_date"
								icon="mdi-calendar-end"
								:errors="formErrors"
							/>
						</v-col>
						<v-col
							cols="12"
						>
							<text-area
								v-model="editedItem.reason"
								label="Reason"
								name="reason"
								icon="mdi-calendar-text"
								:errors="formErrors"
							/>
						</v-col>
						<v-col
							cols="12"
						>
							<select-field
								v-model="editedItem.status"
								label="Leave status"
								name="status"
								:items="$helper.STATUS_CHOICES"
								item-value="code"
								item-text="name"
								icon="mdi-list-status"
								:errors="formErrors"
							/>
						</v-col>
						<v-col
							cols="12"
						>
							<text-area
								v-model="editedItem.status_message"
								label="Status message"
								name="status_message"
								icon="mdi-message"
								:errors="formErrors"
							/>
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>

			<v-card-actions class="grey lighten-4">
				<v-spacer/>
				<v-btn
					color="error darken-1"
					text rounded
					@click="close"
				>
					Cancel
				</v-btn>
				<v-btn
					:loading="saving"
					color="blue darken-1"
					text rounded
					@click="save"
				>
					Save
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import TableDialogMixin from "@/mixins/TableDialogMixin.js";

export default {
	name: "StaffCreateUpdateDialog",
	mixins: [TableDialogMixin],
	data: () => ({
		model: "leave",
		datesToFormat: ["from_date", "to_date"],
		requiredFields: ["from_date"],
		editedItem: {
			requested_by_name: null,
			from_date: null,
			to_date: null,
			reason: null,
			leave_type: null,
			status: null,
			status_message: null,
			user: null,
			recommended_by: null,
			approved_by: null,
			rejected_by: null,
		}
	}),
	computed: {
		...mapGetters({
			detail: "leave/inEdit",
			formErrors: "leave/formErrors"
		}),
		formTitle() {
			if (this.detail) return "Edit Leave Request"
			else return "New Leave Request"
		},
		getEditFormData() {
			return {
				from_date: this.editedItem.from_date,
				to_date: this.editedItem.to_date,
				reason: this.editedItem.reason,
				leave_type: this.editedItem.leave_type,
				status: this.editedItem.status,
				status_message: this.editedItem.status_message,
			}
		}
	},
	methods: {
		...mapMutations("leave", ["SET_FOR_EDIT", "SET_DIALOG_STATE", "SET_FORM_ERRORS"]),
	}
}
</script>

<style scoped lang="scss">
@import "src/styles/fonts.scss";
</style>
