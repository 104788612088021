// Required Data:
// - datesToFormat: Array
// - requiredFields: Array
// Tips:
// - save store detail as "detail"
// - init event is thrown after successful edit or create

import Snack from "@/mixins/Snack.js";

const TableDialogMixin = {
	mixins: [Snack],
	data: () => ({
		saving: false,
	}),
	computed: {
		dialog: {
			get() {
				return this.$store.state[this.model].editorDialog
			},
			set(v) {
				this.SET_DIALOG_STATE(v)
			}
		},
	},
	watch: {
		detail(newV, prevV) {
			if (prevV !== newV && newV) {
				this.editedItem = { ...newV }
				// parse date to fit in form
				if (this.datesToFormat && this.datesToFormat.length) {
					this.datesToFormat.forEach(field => {
						this.editedItem[field] = this.editedItem[field]
							? this.$formatUnixDate(this.editedItem[field])
							: null
					})
				}
				// parse time to fit in form
				if(this.timeToFormat && this.timeToFormat.length) {
					this.timeToFormat.forEach(field => {
						this.editedItem[field] = this.editedItem[field]
							? this.$unixTime(this.editedItem[field])
							: null
					})
				}
				// create user object for autocomplete
				if (this.editedItem && this.editedItem.user) {
					this.user = {
						name: this.editedItem.user_name,
						id: this.editedItem.user
					}
				} else {
					this.user = null
				}
			}
		}
	},
	methods: {
		close () {
			this.SET_FOR_EDIT({})
			this.SET_DIALOG_STATE(false)
			this.SET_FORM_ERRORS({})
		},
		checkRequired(fieldList) {
			let errObj = {}
			fieldList.forEach(field => {
				if (!this.editedItem[field]) errObj[field] = ["This field is required."]
			})
			this.SET_FORM_ERRORS(errObj)
			return Object.entries(errObj).length > 0
		},
		async create() {
			this.saving = true
			try {
				await this.$axios.post(this.$urls[this.model].list, this.getCreateFormData)
				this.$emit("init")
				this.close()
				await this.openSnack({text: `${this.$helper.capitalize(this.model)} created successfully.`, color: "success"})
			} catch (e) {
				const statusCode = e.response.status
				if (statusCode < 500 && statusCode >= 400) {
					this.SET_FORM_ERRORS(e.response.data)
				}
			} finally {
				this.saving = false
			}
		},
		async edit() {
			this.saving = true
			try {
				await this.$axios.put(
					this.$util.format(this.$urls[this.model].detail, this.detail.id),
					this.getEditFormData
				)
				this.$emit("init")
				this.close()
				await this.openSnack({text: `${this.$helper.capitalize(this.model)} updated successfully.`, color: "success"})
			} catch (e) {
				const statusCode = e.response.status
				if (statusCode < 500 && statusCode >= 400) {
					this.SET_FORM_ERRORS(e.response.data)
				}
			} finally {
				this.saving = false
			}
		},
		async save() {
			if(this.checkRequired(this.requiredFields)) return
			if (this.detail) {
				await this.edit()
			} else {
				await this.create()
			}
		}
	},
}

export default TableDialogMixin
